import { createStore } from "vuex";
const Server_Url = "";
// const Server_Url = "http://localhost/courses/public/";
export default createStore({
  state: {
    Api_Url: Server_Url + "php/index.php",
    CurrentWidth: window.innerWidth,
    CurrentHeight: window.innerHeight,
    LoaderIndex: 0,
    SideMenuIndex: 0,
    CurrentComponent: "NoPage",
    UserInfo: {
      user_id: 0,
      user_name: 0,
      user_phone: 0,
      user_courses: [],
    },
  },
  getters: {},
  mutations: {
    // OpenLoader() {
    //   this.state["LoaderIndex"] = 1;
    // },
  },
  actions: {
    // ResizePage: ({ state }, payload) => {
    //   state.CurrentWidth = payload["WindowWidth"];
    // },
  },
  modules: {},
});
