import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Pagination from "v-pagination-3";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import "animate.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueTelInput from "vue-tel-input";
// import "vue-tel-input/dist/vue-tel-input.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import {} from "@fortawesome/free-brands-svg-icons";
import {
  faBars,
  faCircleCheck,
  faCircleXmark,
  faClock,
  faEye,
  faEyeSlash,
  faFilePen,
  faGear,
  faHouse,
  faPenToSquare,
  faPeopleGroup,
  faPhone,
  faPowerOff,
  faTableList,
  faTrash,
  faUser,
  faUserPen,
  faUserPlus,
  faUsersRectangle,
} from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
library.add(
  faPowerOff,
  faUser,
  faEye,
  faEyeSlash,
  faPhone,
  faHouse,
  faGear,
  faPeopleGroup,
  faTableList,
  faCalendar,
  faClock,
  faUserPlus,
  faUserPen,
  faPenToSquare,
  faTrash,
  faUsersRectangle,
  faCircleXmark,
  faCircleCheck,
  faBars,
  faPhone,
  faFilePen
);

createApp(App)
  .use(store)
  .use(router)
  .use(VueSweetalert2)
  .use(VueTelInput)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("pagination", Pagination)
  .mount("#app");

// npm install axios
// npm install bootstrap
// npm install jquery
// npm install @vuelidate/core @vuelidate/validators
// npm install @fortawesome/fontawesome-svg-core
// npm install @fortawesome/free-solid-svg-icons
// npm install @fortawesome/free-regular-svg-icons
// npm install @fortawesome/free-brands-svg-icons
// npm install @fortawesome/vue-fontawesome@latest-3
// npm install animate.css
// npm install -S vue-sweetalert2
// npm install wowjs
// npm install vue3-carousel
// npm install v-pagination-3
// npm install vue-tel-input
