<template>
  <MainLoader />
  <router-view />
</template>

<script>
// oncontextmenu="return false;"
// Using of WoW
// import WOW from "wowjs";
// mounted() {
// new WOW.WOW({
//   live: false,
// }).init();
//   },
import MainLoader from "@/components/MainLoader.vue";
import $ from "jquery";
window.$ = window.jQuery = require("jquery");
export default {
  name: "AppView",
  components: { MainLoader },
  data() {
    return {};
  },
  created() {
    let main = this;
    $(window).resize(function () {
      main.$store.state["CurrentWidth"] = window.innerWidth;
      main.$store.state["CurrentHeight"] = window.innerHeight;
    });
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Alexandria:wght@100;300;400;600;700;800;900&display=swap");

:root {
  --DarkBlue: #031926;
  --LightBlue: #9dbebb;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Alexandria", sans-serif;
}

html {
  scroll-behavior: smooth;
}

p,
h1,
h2,
h3,
span,
ul {
  margin: 0 !important;
}
@media print {
  html,
  body {
    display: none; /* hide whole page */
  }
}
body {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #f2f2f2;
  #app {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    // @media screen and (max-width: 767px) {
    //   padding: 0 1rem;
    // }
  }
}

@media screen and (min-width: 100px) {
  /* font at xs */
  html {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 768px) {
  /* font at sm */
  html {
    font-size: 13px !important;
  }
}

@media screen and (min-width: 991px) {
  /* font at md */
  html {
    font-size: 15px !important;
  }
}

@media screen and (min-width: 1200px) {
  /* font at lg */
  html {
    font-size: 16px !important;
  }
}

// p.VuePagination__count {
//   text-align: center;
//   font-size: 1rem;
//   padding-top: 0.5rem;
// }
// .swal2-title {
//   font-size: 1.4rem;
// }
</style>
